import SpinnerLoader from 'components/performance-spinner';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import stringSimilarity from 'string-similarity';
import './style.scss';

class CSVWizard extends Component {
  constructor(props, context) {
    super(props, context);
    this.selectRow = this.selectRow.bind(this);
    this.getRow = this.getRow.bind(this);
    this.totalWeight = this.totalWeight.bind(this);
    this.state = {
      mappedFields: {}
    };
  }

  UNSAFE_componentWillMount() {
    const { fields } = this.props;
    const mappedFields = {};
    fields.forEach(f => {
      mappedFields[f] = this.getRow(f);
    });
    this.setState({
      mappedFields
    });
  }

  selectRow(field, value) {
    const { mappedFields } = this.state;
    this.setState({
      mappedFields: {
        ...mappedFields,
        [field]: value
      }
    });
  }

  getRow(v) {
    const { rows } = this.props;
    const index = _.findIndex(rows, r => stringSimilarity.compareTwoStrings(r, v) >= 0.8);
    if (index >= 0) return rows[index];

    return '';
  }

  disabledSubmit() {
    const { mappedFields } = this.state;
    let disabled = false;
    _.forOwn(mappedFields, (value, _) => {
      if (!value) disabled = true;
    });
    return disabled;
  }

  stepSubmit() {
    const { mappedFields } = this.state;
    const { processFields, currentStep, endWizard } = this.props;
    if (currentStep === 1) processFields(mappedFields);
    if (currentStep === 3) endWizard();
  }

  totalWeight() {
    const { positions } = this.props;
    if (positions && positions.length) {
      const weightTotal = positions.reduce((total, pos) => total + pos.value, 0);
      return parseFloat(weightTotal.toFixed(2));
    }
    return 0;
  }

  render() {
    const { mappedFields } = this.state;
    const { rows, fields, currentStep, positions, prospectModal } = this.props;
    const [positionsWithSecurity, positionsWithoutSecurity] = _.partition(positions, 'security');
    return (
      <div id="CSVWizard">
        {currentStep === 1 && (
          <div className={`step${currentStep}`}>
            <h3>Select your spreadsheet data to StratiFi fields.</h3>
            {fields.map(f => (
              <div key={`${f}-key`}>
                <label htmlFor={`${f}_id`}>{f}:</label>
                <div className="c-select-wrap">
                  <select
                    id={`${f}_id`}
                    className="c-select"
                    onChange={event => this.selectRow(f, event.target.value)}
                    defaultValue={mappedFields[f]}
                  >
                    <option value="">Select</option>
                    {rows.map(r => (
                      <option
                        key={`${r}-key`}
                        value={r}
                        disabled={mappedFields[_.find(fields, x => x !== f)] === r}
                      >
                        {r}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
          </div>
        )}
        {currentStep === 2 && (
          <div className={`step${currentStep}`}>
            <SpinnerLoader spinnerLoading />
            <h4 className="processing">Processing Securities</h4>
          </div>
        )}
        {currentStep === 3 && (
          <div className={`step${currentStep}`}>
            <h4>Summary Report</h4>
            <div className="report">
              {!!positionsWithSecurity.length && (
                <span className="report-row success">
                  <span className="icon-checkmark-circle" />
                  {positionsWithSecurity.length} Securities uploaded successfully.
                </span>
              )}
              {!!positionsWithoutSecurity.length && (
                <span className="report-row failed">
                  <span className="icon-remove" />
                  {positionsWithoutSecurity.length} Securities were not found in our system.
                  <ul>
                    <li>
                      {positionsWithoutSecurity.map(({ ticker }, index) =>
                        index + 1 === positionsWithoutSecurity.length ? `${ticker}` : `${ticker}, `
                      )}
                    </li>
                  </ul>
                </span>
              )}
              {this.totalWeight() !== 100 && !prospectModal && (
                <span className="report-row failed">
                  <span className="icon-remove" />
                  Total is less than 100%
                </span>
              )}
            </div>
          </div>
        )}
        {currentStep !== 2 && (
          <div className="actions">
            <button
              className="btn btn-primary"
              onClick={() => this.stepSubmit()}
              disabled={this.disabledSubmit()}
              tabIndex="0"
              type="button"
              onKeyDown={() => {}}
            >
              {currentStep === 1
                ? 'Continue'
                : `View the ${prospectModal ? 'account' : 'model Portfolio'}`}
            </button>
          </div>
        )}
      </div>
    );
  }
}

CSVWizard.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  processFields: PropTypes.func.isRequired,
  endWizard: PropTypes.func.isRequired,
  positions: PropTypes.arrayOf(
    PropTypes.shape({
      ticker: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired
};

export default CSVWizard;
