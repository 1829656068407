import { questionFlavors as qf, questionTypes as qt } from 'constants/questionnaire';
import { OTHER_VALUE } from 'containers/risk-tolerance-questionnaire/question/goal-approach/constants';
import { BackendValidation } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { validateAddress } from 'components/advisor/address-form';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { NumberValidator, validation } from 'utils/form';
import { ageCalculator, interpolateQuestions } from 'utils/questionnaire';

const parseNumber = value =>
  typeof value === 'number' ? value : parseFloat(value.replace(/,/g, ''));

const validate = (values, props) => {
  const errors = {};
  props.questions.forEach(q => {
    const key = q.slug || `question-${q.position}`;
    const value = values[key];
    let error;

    if (
      (q.question_type === qt.CUSTOM || q.question_type === qt.LEGACY) &&
      q.data?.flavor === qf.BIRTHDAY_RETIREMENT
    ) {
      const age = value.birthday ? ageCalculator(value.birthday) : 0;
      const birthdayValidator = new NumberValidator(age).required().min(1).max(100);
      const retirementValidator = new NumberValidator(value.retirement).required().min(1);
      error = {
        birthday: birthdayValidator.error,
        employment:
          validation.required(value.employment) || validation.minLength(value.employment, 1),
        retirement: retirementValidator.max(age + 100).error
      };
    } else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.ADDRESS)
      error = validateAddress(value, 'address');
    else if (
      (q.question_type === qt.CUSTOM || q.question_type === qt.LEGACY) &&
      (q.data?.flavor === qf.TOLERABLE_LOSS || q.data?.flavor === qf.SINGLE_YEAR_OUTCOME)
    )
      ({ error } = new NumberValidator(value).required());
    else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.GOAL_APPROACH)
      error = {
        approach: validation.required(value.approach) || validation.minLength(value.approach, 1),
        goal: validation.required(value.goal) || validation.minLength(value.goal, 1),
        other_approach:
          value.approach &&
          value.approach.includes(OTHER_VALUE) &&
          validation.required(value.other_approach),
        other_goal:
          value.goal && value.goal.includes(OTHER_VALUE) && validation.required(value.other_goal)
      };
    else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.ANNUAL_TAXABLE_INCOME) {
      const incomeValidator = new NumberValidator(value.income).required().min(0);
      const contributionValidator = new NumberValidator(value.contribution)
        .required()
        .min(0)
        .max(1);
      error = { income: incomeValidator.error, contribution: contributionValidator.error };
    } else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.CASH_INVESTABLE_ASSETS) {
      const investmentValidator = new NumberValidator(value.investment).required().min(0);
      const percentageValidator = new NumberValidator(value.percentage).required().min(0).max(1);
      error = { investment: investmentValidator.error, percentage: percentageValidator.error };
    } else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.VALUE_REQUIREMENT) {
      const amount = new NumberValidator(value.amount).required().error;
      const type = validation.required(value.type);
      error = { amount, type };
    } else if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.WITHDRAWAL_REQUIREMENT) {
      const type = validation.required(value.type);
      const amount = new NumberValidator(value.amount).required().error;
      const start = new NumberValidator(value.start).required().error;
      const duration = new NumberValidator(value.duration).required().error;
      error = { type, amount, start, duration };
      if (q.data?.is_entity) {
        const birthday = validation.required(value.birthday);
        error.birthday = birthday;
      }
    } else if (
      (q.question_type === qt.CUSTOM || q.question_type === qt.LEGACY) &&
      q.data?.flavor === qf.EXPECTED_RETURN
    ) {
      const needed = new NumberValidator(value.needed).required().min(0).error;
      const expected = new NumberValidator(value.expected).required().min(0).error;
      error = { needed, expected };
      if (q.question_type === qt.LEGACY)
        error.amount = new NumberValidator(value.amount).required().error;
    } else if (
      q.question_type === qt.CHOICE ||
      (q.question_type === qt.CUSTOM && q.data?.flavor === qf.PORTFOLIO_CONCENTRATION) ||
      (q.question_type === qt.CUSTOM && q.data?.flavor === qf.SINGLE_YEAR_OUTCOME)
    )
      error = validation.required(value) || validation.minLength(value, 1);
    else if (Number.isFinite(q.data?.min) && Number.isFinite(value))
      error = validation.required(value) || validation.min(value, q.data?.min);
    else if (q.question_type === qt.SECTION) {
      // This condition avoids falling into the default condition.
      // For this case, no validation is required.
    } else error = validation.required(value);

    errors[key] = errors[key] || error;
  });
  return errors;
};

const processAnswer = (question, answer) => {
  let data;

  if (
    (question.question_type === qt.CUSTOM &&
      (question.data?.flavor === qf.ADDRESS ||
        question.data?.flavor === qf.ANNUAL_TAXABLE_INCOME ||
        question.data?.flavor === qf.BIRTHDAY_RETIREMENT ||
        question.data?.flavor === qf.CASH_INVESTABLE_ASSETS ||
        question.data?.flavor === qf.EXPECTED_RETURN ||
        question.data?.flavor === qf.GOAL_APPROACH ||
        question.data?.flavor === qf.VALUE_REQUIREMENT ||
        question.data?.flavor === qf.WITHDRAWAL_REQUIREMENT)) ||
    (question.question_type === qt.LEGACY &&
      (question.data?.flavor === qf.BIRTHDAY_RETIREMENT ||
        question.data?.flavor === qf.EXPECTED_RETURN))
  )
    data = answer;
  else if (question.question_type === qt.NUMERIC) data = { value: parseNumber(answer) };
  else if (question.question_type === qt.CHOICE && !Array.isArray(answer))
    data = { value: [answer] };
  else data = { value: answer };

  return {
    answer: data,
    position: question.position,
    question_number: question.question_number,
    question_version: question.id
  };
};

const processAnswers = (values, questions) => {
  const answers = Object.entries(values).map(([slug, answer]) => {
    const question = questions.find(question => question.slug === slug);
    return processAnswer(question, answer);
  });

  return answers;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const getAnswerFromQuestion = question => {
  const { answer } = question;
  if (
    (question.question_type === qt.CUSTOM &&
      (question.data?.flavor === qf.ADDRESS ||
        question.data?.flavor === qf.ANNUAL_TAXABLE_INCOME ||
        question.data?.flavor === qf.BIRTHDAY_RETIREMENT ||
        question.data?.flavor === qf.CASH_INVESTABLE_ASSETS ||
        question.data?.flavor === qf.EXPECTED_RETURN ||
        question.data?.flavor === qf.GOAL_APPROACH ||
        question.data?.flavor === qf.VALUE_REQUIREMENT ||
        question.data?.flavor === qf.WITHDRAWAL_REQUIREMENT)) ||
    (question.question_type === qt.LEGACY &&
      (question.data?.flavor === qf.BIRTHDAY_RETIREMENT ||
        question.data?.flavor === qf.EXPECTED_RETURN))
  )
    return answer;

  return answer?.value;
};

const getAnswerFromField = (field, question) => {
  if (!field) return null;

  let answer;
  if (
    (question.question_type === qt.CUSTOM || question.question_type === qt.LEGACY) &&
    question.data?.flavor === qf.BIRTHDAY_RETIREMENT &&
    field.birthday?.value &&
    field.employment?.value
  )
    answer = {
      birthday: field.birthday?.value,
      retirement: field.retirement?.value,
      employment: field.employment?.value
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.ADDRESS &&
    field.address?.value &&
    field.city?.value &&
    field.country?.value
  )
    answer = {
      address: field.address?.value,
      city: field.city?.value,
      country: field.country?.value,
      postcode: field.postcode?.value,
      state: field.state?.value
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.ANNUAL_TAXABLE_INCOME &&
    !Number.isNaN(Number(field.income?.value))
  )
    answer = {
      income: field.income?.value,
      contribution: field.contribution?.value
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.CASH_INVESTABLE_ASSETS &&
    !Number.isNaN(Number(field.investment?.value))
  )
    answer = {
      investment: field.investment?.value,
      percentage: field.percentage?.value
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.GOAL_APPROACH &&
    field.approach?.value &&
    field.goal?.value
  )
    answer = {
      approach: field.approach.value,
      goal: field.goal.value,
      other_approach: field.other_approach?.value || undefined,
      other_goal: field.other_goal?.value || undefined
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.VALUE_REQUIREMENT &&
    !Number.isNaN(Number(field.amount?.value))
  )
    answer = {
      amount: field.amount?.value,
      type: field.type?.value
    };
  else if (
    question.question_type === qt.CUSTOM &&
    question.data?.flavor === qf.WITHDRAWAL_REQUIREMENT &&
    !Number.isNaN(Number(field.start?.value)) &&
    !Number.isNaN(Number(field.amount?.value))
  ) {
    answer = {
      type: field.type?.value,
      amount: field.amount?.value,
      start: field.start?.value,
      duration: field.duration?.value
    };
    if (question.data?.is_entity && field.birthday?.value) answer.birthday = field.birthday?.value;
  } else if (
    (question.question_type === qt.CUSTOM || question.question_type === qt.LEGACY) &&
    question.data?.flavor === qf.EXPECTED_RETURN &&
    !Number.isNaN(Number(field.needed?.value))
  ) {
    answer = {
      needed: field.needed?.value,
      expected: field.expected?.value
    };
    if (question.question_type === qt.LEGACY) answer.amount = field.amount?.value;
  } else if (
    (question.question_type === qt.CUSTOM || question.question_type === qt.LEGACY) &&
    (question.data?.flavor === qf.TOLERABLE_LOSS ||
      question.data?.flavor === qf.SINGLE_YEAR_OUTCOME) &&
    Number.isFinite(field.value)
  )
    answer = { value: field.value };
  else if (field.value || field.value === 0) answer = { value: field.value };
  return answer;
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { form } = stateProps;
  const { rawQuestions, registerError, saveQuestionnaire } = ownProps;

  const initialValues = rawQuestions.reduce((acum, question) => {
    const key = question.slug || `question-${question.position}`;
    const answer = getAnswerFromQuestion(question);
    return _.isNil(answer) ? acum : { ...acum, [key]: answer };
  }, {});

  const answers = rawQuestions.reduce((acum, question) => {
    const key = question.slug || `question-${question.position}`;
    const answer = getAnswerFromField(form?.[key], question) || initialValues[key];
    return answer ? { ...acum, [key]: answer } : acum;
  }, {});

  const questions = interpolateQuestions(rawQuestions, answers);

  const onSubmit = values => {
    const answers = processAnswers(values, questions);
    saveQuestionnaire(answers)
      .then(response => {
        if (response.error) {
          toast.error(() => <FormattedMessage id="rtq.common.error" />);
          const answerErrors = response.error?.errors?.answers;
          if (answerErrors) {
            const errors = answerErrors.reduce(
              (acum, question) =>
                question?.slug ? { ...acum, [question.slug]: question?.data || question } : acum,
              {}
            );
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ error: { errors } });
          }
        }
        return response;
      })
      .catch(registerError);
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    answers,
    hasPreviousAnswers: !_.isEmpty(initialValues),
    initialValues,
    onSubmit,
    questions
  };
};

const withRiskToleranceForm =
  ({ form }) =>
  WrappedComponent => {
    const WithRiskToleranceForm = ({ questions: rawQuestions, ...props }) => {
      const fields = useMemo(
        () =>
          rawQuestions.reduce((acum, q) => {
            const prefix = q.slug || `question-${q.position}`;

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.ADDRESS)
              return [
                ...acum,
                `${prefix}.address`,
                `${prefix}.city`,
                `${prefix}.country`,
                `${prefix}.postcode`,
                `${prefix}.state`
              ];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.ANNUAL_TAXABLE_INCOME)
              return [...acum, `${prefix}.income`, `${prefix}.contribution`];

            if (
              (q.question_type === qt.CUSTOM || q.question_type === qt.LEGACY) &&
              q.data?.flavor === qf.BIRTHDAY_RETIREMENT
            )
              return [
                ...acum,
                `${prefix}.birthday`,
                `${prefix}.retirement`,
                `${prefix}.employment`
              ];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.CASH_INVESTABLE_ASSETS)
              return [...acum, `${prefix}.investment`, `${prefix}.percentage`];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.EXPECTED_RETURN)
              return [...acum, `${prefix}.expected`, `${prefix}.needed`];

            if (q.question_type === qt.LEGACY && q.data?.flavor === qf.EXPECTED_RETURN)
              return [...acum, `${prefix}.expected`, `${prefix}.needed`, `${prefix}.amount`];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.GOAL_APPROACH)
              return [
                ...acum,
                `${prefix}.goal`,
                `${prefix}.approach`,
                `${prefix}.other_goal`,
                `${prefix}.other_approach`
              ];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.VALUE_REQUIREMENT)
              return [...acum, `${prefix}.amount`, `${prefix}.type`];

            if (q.question_type === qt.CUSTOM && q.data?.flavor === qf.WITHDRAWAL_REQUIREMENT)
              return [
                ...acum,
                `${prefix}.amount`,
                `${prefix}.type`,
                `${prefix}.start`,
                `${prefix}.duration`,
                `${prefix}.birthday`
              ];

            return [...acum, `${prefix}`];
          }, []),
        [JSON.stringify(rawQuestions)]
      );

      const RiskToleranceForm = useMemo(
        () =>
          reduxForm(
            {
              form,
              fields,
              // initialValues,
              touchOnChange: true,
              validate
            },
            null,
            null,
            mergeProps
          )(BackendValidation(WrappedComponent)),
        []
      );

      return <RiskToleranceForm {...props} rawQuestions={rawQuestions} />;
    };

    WithRiskToleranceForm.displayName = `WithRiskToleranceForm(${getDisplayName(
      WrappedComponent
    )})`;

    WithRiskToleranceForm.propTypes = {
      questions: PropTypes.arrayOf(PropTypes.object).isRequired
    };

    return WithRiskToleranceForm;
  };

export default withRiskToleranceForm;
