import PropTypes from 'prop-types';
import React from 'react';
import { capFirstChar } from 'utils/utils';
import SubscriptionBanner from '..';

const SubscriptionUpgradeBanner = ({ plan }) => (
  <SubscriptionBanner url={process.env.STARTER_LANDING_PAGE_URL}>
    You are using the {capFirstChar(plan)} Plan. Got Questions? Click here.
  </SubscriptionBanner>
);

SubscriptionUpgradeBanner.propTypes = {
  plan: PropTypes.string.isRequired
};

export default SubscriptionUpgradeBanner;
