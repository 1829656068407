import classnames from 'classnames';
import PropTypes from 'prop-types';
import ActionProvider from 'providers/actions';
import AuthProvider from 'providers/auth';
import IntegrationProvider from 'providers/integrations';
import UserProvider from 'providers/user';
import React, { Component } from 'react';
import NewTag from 'app/containers/advisor/new-tag';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { integrationsUnassignedAccountsCountSelector } from 'selectors/integrations';
import { trackAmplitudeEvent } from 'utils/tracking';

class SideBarAdvisor extends Component {
  constructor(props) {
    super(props);
    this.state = { dropdownHidden: { compliance: true, settings: true, models: true } };
  }

  componentDidMount() {
    const { integrationProvider, location } = this.props;
    integrationProvider.es.countUnassignedSyncedAccounts();
    trackAmplitudeEvent('advisor.navigation', { path: location.pathname });
  }

  onSettingsClick = () => {
    const { dropdownHidden } = this.state;
    this.setState({ dropdownHidden: { ...dropdownHidden, settings: !dropdownHidden.settings } });
  };

  onComplianceClick = () => {
    const { dropdownHidden } = this.state;
    this.setState({
      dropdownHidden: { ...dropdownHidden, compliance: !dropdownHidden.compliance }
    });
  };

  onModelsClick = () => {
    const { dropdownHidden } = this.state;
    this.setState({
      dropdownHidden: { ...dropdownHidden, models: !dropdownHidden.models }
    });
  };

  isRouteActive = (item, url) =>
    item.title === 'Dashboard'
      ? url === item.route
      : url.includes(item.route) ||
        !!(item.relatedRoutes && item.relatedRoutes.filter(r => url.includes(r)).length);

  isRouteCurrentActive = (item, url) =>
    (item.referencedRoutes && item.referencedRoutes.filter(r => url.includes(r)).length) ||
    (this.isRouteActive(item, url) && url.includes(item.route));

  handleClick = (event, { title, route, disabled }) => {
    event.preventDefault();
    if (disabled) return;
    const { routerActions, actionProvider } = this.props;
    actionProvider.slack({ verb: `navbar${title.replace(/\s+/g, '')}` });
    routerActions.push({ pathname: route });
  };

  isItemCurrentActive(items) {
    const { location } = this.props;
    return items.some(item => this.isRouteCurrentActive(item, `/${location.pathname}`));
  }

  isItemActive = items => {
    const { location } = this.props;
    return items.some(item => this.isRouteActive(item, `/${location.pathname}`));
  };

  isItemHidden = item => {
    const { dropdownHidden } = this.state;
    if (item.title === 'Settings') return dropdownHidden.settings;
    if (item.title === 'Compliance') return dropdownHidden.compliance;
    if (item.title === 'Model Portfolios') return dropdownHidden.models;
    return false;
  };

  onDropdownItemClick = (e, item) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (item && item.title === 'Settings') this.onSettingsClick();
    else if (item && item.title === 'Compliance') this.onComplianceClick();
    else if (item && item.title === 'Model Portfolios') this.onModelsClick();
  };

  render() {
    const {
      location,
      shown,
      user,
      authProvider,
      totalNewInvestors,
      unassignedAccounts,
      userProvider
    } = this.props;

    const disableDashboard = !authProvider.isAnyAdvisor(user);
    const isEntry = userProvider.isEntry(user);
    const isProfessional = userProvider.isProfessional(user);

    const { loggedUser } = authProvider;

    const navigation = [
      {
        title: 'Clients',
        route: '/advisor/investors',
        disabled: disableDashboard,
        locked: isEntry,
        indent: false
      },
      {
        title: 'Prospects',
        route: '/advisor/prospects',
        disabled: disableDashboard,
        indent: false
      },
      {
        title: 'Households',
        route: '/advisor/households',
        disabled: disableDashboard,
        locked: isEntry,
        indent: false
      },
      {
        title: 'Model Portfolios',
        disabled: disableDashboard,
        indent: false,
        dropdown: true,
        items: [
          {
            title: 'Workspace',
            route: '/advisor/models',
            locked: isEntry || isProfessional,
            disabled: false,
            indent: true
          },
          {
            title: 'Market IQ',
            route: '/advisor/marketplace',
            hidden:
              !authProvider.hasCompliancePermissions(user) &&
              !user?.advisor?.company?.allow_advisors_models_marketplace_access,
            disabled: false,
            indent: true
          }
        ]
      },
      {
        title: `Custom Securities`,
        route: '/advisor/securities',
        disabled: disableDashboard,
        indent: false,
        hidden:
          !authProvider.hasCompliancePermissions(user) &&
          !user?.advisor?.company?.allow_advisors_custom_securities_access
      },
      {
        title: 'Analytics',
        route: '/advisor/analytics',
        disabled: disableDashboard,
        locked: isEntry || isProfessional,
        indent: false
      },
      {
        title: 'Integrations',
        route: '/advisor/integrations',
        locked: isEntry,
        indent: false,
        hidden:
          !authProvider.hasCompliancePermissions(user) &&
          !user?.advisor?.company?.allow_advisors_integrations_access
      },
      {
        title: 'Accounts',
        route: '/advisor/accounts/',
        locked: isEntry,
        indent: false,
        hidden:
          !authProvider.hasCompliancePermissions(user) &&
          !user?.advisor?.company?.allow_advisors_integrations_access
      },
      {
        title: 'Drift Exceptions',
        route: '/advisor/drift-exceptions',
        hidden: authProvider.hasCompliancePermissions(user),
        locked: isEntry || isProfessional,
        indent: false
      },
      {
        title: 'Compliance',
        locked: isEntry,
        indent: false,
        hidden: !authProvider.hasCompliancePermissions(user),
        dropdown: true,
        items: [
          {
            title: 'Surveillance',
            route: '/advisor/surveillance',
            locked: isEntry || isProfessional,
            indent: true
          },
          {
            title: 'Drift Exceptions',
            route: '/advisor/drift-exceptions',
            locked: isEntry || isProfessional,
            indent: true
          }
        ]
      },
      {
        title: 'Settings',
        disabled: disableDashboard,
        indent: false,
        dropdown: true,
        items: [
          {
            title: 'Business Profile',
            route: '/advisor/me/company',
            disabled: false,
            indent: true,
            hidden: !authProvider.hasCompliancePermissions(user)
          },
          {
            title: 'My Profile',
            route: '/advisor/me/profile',
            disabled: false,
            indent: true
          },
          {
            title: 'Configuration',
            route: '/advisor/settings/configurations',
            disabled: false,
            indent: true,
            hidden:
              !authProvider.hasCompliancePermissions(user) ||
              !(user?.advisor?.company?.product === 'enterprise')
          },
          {
            title: 'Templates',
            route: '/advisor/templates/',
            disabled: disableDashboard,
            indent: true,
            hidden:
              !authProvider.hasCompliancePermissions(user) &&
              !user?.advisor?.company?.allow_advisors_creating_templates
          },
          {
            title: 'Collaboration Hub',
            route: '/advisor/me/teams',
            disabled: disableDashboard,
            locked: isEntry || isProfessional,
            indent: true
          },
          {
            title: 'Users',
            route: '/advisor/me/users',
            disabled: disableDashboard,
            indent: true,
            hidden: !authProvider.hasManagerPermissions(user)
          },
          {
            title: 'Billing',
            route: '/advisor/me/billing',
            disabled: disableDashboard,
            indent: true,
            hidden: !authProvider.hasAdminPermissions(user)
          },
          {
            title: 'Security',
            route: '/advisor/me/security',
            disabled: disableDashboard,
            indent: true
          }
        ]
      }
    ];

    return (
      <nav className="navigation-left-bar">
        <div className="navigation-options">
          {navigation.map(item =>
            !item.dropdown ? (
              <Link
                key={`Link-${item.title}`}
                to={item.route}
                className={classnames('nav-item', 'nav-link', {
                  active: this.isRouteActive(item, `/${location.pathname}`),
                  disabled: item.disabled,
                  locked: item.locked,
                  indent: item.indent,
                  'current-active': !this.isRouteCurrentActive(item, `/${location.pathname}`),
                  hide: item.hidden
                })}
                onClick={e => {
                  this.handleClick(e, item);
                  trackAmplitudeEvent('advisor.navigation', { path: item.route });
                }}
              >
                {shown && (
                  <>
                    <span
                      className={classnames({
                        navigation_options_new: item.title === 'Custom Securities'
                      })}
                    >
                      {item.title}
                      {item.title === 'Custom Securities' && (
                        <div className="navigation_options_new__new-tag__position">
                          <NewTag />
                        </div>
                      )}
                      {item.title === 'Clients' && !!totalNewInvestors ? (
                        <span className="notification-dot" />
                      ) : null}
                      {item.title === 'Accounts' && !!unassignedAccounts ? (
                        <span className="notification-dot" />
                      ) : null}
                    </span>
                    {(item.disabled || item.locked) && (
                      <img src="/img/icons/lock.svg" alt="Locked" />
                    )}
                  </>
                )}
              </Link>
            ) : (
              <div key={`Sub-${item.title}`}>
                <a
                  href="#submenu"
                  type="button"
                  key={`Link-${item.title}`}
                  className={classnames('nav-item', 'nav-link', {
                    active: this.isItemActive(item.items),
                    disabled: item.disabled,
                    indent: item.indent,
                    'current-active': !this.isItemCurrentActive(item.items),
                    hide: item.hidden,
                    dropdownShown: !this.isItemHidden(item)
                  })}
                  onClick={e => {
                    this.onDropdownItemClick(e, item);
                  }}
                >
                  {shown && (
                    <>
                      <span>{item.title}</span>
                      <span className="dropdown-arrow">
                        <i className="icon-arrow" />
                      </span>
                    </>
                  )}
                </a>
                {item.items.map(sub => (
                  <Link
                    key={`Link-${item.title}/${sub.title}`}
                    to={sub.route}
                    className={classnames('nav-item', 'nav-link', {
                      'dropdown-active': this.isRouteActive(sub, `/${location.pathname}`),
                      disabled: sub.disabled,
                      locked: sub.locked,
                      indent: sub.indent,
                      'current-active': !this.isRouteCurrentActive(sub, `/${location.pathname}`),
                      hide: this.isItemHidden(item) || sub.hidden
                    })}
                    onClick={e => {
                      this.handleClick(e, sub);
                      trackAmplitudeEvent('advisor.navigation', { path: sub.route });
                    }}
                  >
                    {shown && (
                      <>
                        <span>{sub.title}</span>
                        {sub.title === 'Market IQ' && <NewTag />}
                        {(sub.disabled || sub.locked) && (
                          <img src="/img/icons/lock.svg" className="icon-lock" alt="Locked" />
                        )}
                      </>
                    )}
                  </Link>
                ))}
              </div>
            )
          )}
        </div>
        <div>
          {loggedUser?.is_staff && (
            <div className="navigation-options help">
              <Link key="Link-Companies" to="advisor/companies">
                <span>
                  <img src="/img/icons/analyzing-data-icon@2x.png" alt="Help" />
                  Companies
                </span>
              </Link>
            </div>
          )}
          {process.env.HELP_LANDING_PAGE_URL && (
            <div className="navigation-options help">
              <a
                href={process.env.HELP_LANDING_PAGE_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-item nav-link current-active"
              >
                <span>
                  <img src="/img/icons/help-menu-icon.svg" alt="Help" />
                  Help
                </span>
              </a>
            </div>
          )}
        </div>
      </nav>
    );
  }
}

SideBarAdvisor.contextTypes = {
  config: PropTypes.object.isRequired
};

SideBarAdvisor.propTypes = {
  actionProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  integrationProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  shown: PropTypes.bool,
  totalNewInvestors: PropTypes.number.isRequired,
  unassignedAccounts: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

SideBarAdvisor.defaultProps = {
  shown: false
};

export default connect(
  state => ({
    location: state.routing.locationBeforeTransitions,
    totalNewInvestors: state.investors.totalNewInvestors,
    unassignedAccounts: integrationsUnassignedAccountsCountSelector(state),
    user: state.auth.user
  }),
  dispatch => ({
    actionProvider: new ActionProvider({ dispatch }),
    authProvider: new AuthProvider({ dispatch }),
    integrationProvider: new IntegrationProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch),
    userProvider: new UserProvider({ dispatch })
  })
)(SideBarAdvisor);
