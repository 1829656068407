/* eslint-disable jsx-a11y/control-has-associated-label */
import ModelEditGeneral, { modes } from 'components/advisor/common-components/general-popup';
import NameModelForm from 'components/advisor/investors/name-model-form';
import ModelsHeader from 'components/advisor/models/header';
import PositionsFormset, { mapPositions } from 'components/advisor/models/positions-formset';
import CSVWizard from 'components/utils/csv-wizard';
import StepsLine from 'components/utils/steps-line';
import { ADDED_PROSPECT_ACCOUNT_MANUALLY } from 'constants/actstream';
import { CustomerSupportEmailLink, CustomerSupportPhoneLink } from 'constants/contact';
import { BackendValidation } from 'hocs/backend-validation';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { trackAmplitudeEvent } from 'utils/tracking';
import _ from 'lodash';
import './styles.scss';

export { modes };

const validate = values => {
  const errors = {};
  errors.name = errors.name || validation.required(values.name);
  errors.positions = (values.positions || []).map(values => {
    const errors = {};
    if (values.deleted) return errors;

    errors.type = errors.type || validation.required(values.type);
    errors.subtype = errors.subtype || validation.required(values.subtype);
    errors.sector = errors.sector || validation.required(values.sector);
    errors.ticker = errors.ticker || validation.required(values.ticker);

    errors.value = errors.value || validation.required(values.value);
    errors.value = errors.value || validation.float(values.value);
    errors.value = errors.value || validation.floatPositive(values.value);
    return errors;
  });

  return errors;
};

const SubmitButton = ({ text, disabled }) => (
  <div>
    <button type="submit" disabled={disabled} className="btn btn-primary btn-lg btn-title">
      {text}
    </button>
  </div>
);

class ModelEditInvestors extends ModelEditGeneral {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state.defaultFields = ['ticker', 'amount'];
  }

  componentDidMount() {
    super.componentDidMount();
    const { wizardCSV } = this.props;
    if (wizardCSV) this.setState({ wizardCSV });
  }

  onSubmit = values => {
    const { accountProvider, actionProvider } = this.context;
    const { account, hideEditModel, investor, mode, onSuccess, reCalculatePrism } = this.props;

    const createAccount = mode === modes.CREATE;
    const positions = mapPositions(values.positions);

    const saveAction = createAccount
      ? accountProvider.create({
          name: values.name,
          held_away: values.held_away,
          investor: investor.id,
          positions
        })
      : accountProvider.update({
          id: account.id,
          name: values.name,
          investor: investor.id,
          positions
        });

    saveAction
      .then(({ data, error }) => {
        if (error && error.errors) return Promise.reject(error.errors);
        if (data.id) {
          toast.success(() => (
            <div>
              Account <b>{values.name}</b> was {createAccount ? 'created' : 'edited'} successfully.
            </div>
          ));
          reCalculatePrism(data.id, createAccount && account.value > 0);

          if (mode === modes.CREATE && investor.is_prospect)
            actionProvider.slack({ verb: ADDED_PROSPECT_ACCOUNT_MANUALLY });

          onSuccess();

          if (hideEditModel) hideEditModel();

          trackAmplitudeEvent('account.created', {
            id: data?.id,
            name: data?.display_name
          });
        }

        return data;
      })
      .catch(errors => Promise.reject(new Error(errors)));
  };

  render() {
    const { marketProvider, accountProvider, authProvider, user } = this.context;
    const {
      account,
      error,
      errors,
      fields,
      handleSubmit,
      invalid,
      investor,
      marketStore,
      onCSVInputChange,
      positions,
      submitting
    } = this.props;
    let { model } = this.props;

    const isComplianceOrAbove = authProvider.hasCompliancePermissionsOrAbove(user);

    const { copy, wizardCSV, defaultFields } = this.state;

    model = model || {};
    const marketSecurities = marketStore.securities.search || [];

    // calculate total portfolio value
    const total = fields.positions.reduce((sum, position) => {
      try {
        sum += position.deleted.value ? 0 : Number(position.value.value) || 0;
      } catch {} // eslint-disable-line no-empty
      return sum;
    }, 0);

    const copyButtonProps = model.id
      ? {
          onClick: this.setCopy,
          disabled: invalid || total.toFixed(3) !== 100.0 || submitting || copy
        }
      : null;

    if (wizardCSV.show) {
      const steps = ['Assigning Fields', 'Processing', 'Result'];
      return (
        <div className="assign-rows">
          <div className="csv-header-actions">
            <span
              className="icon-remove"
              onClick={() => this.onCSVModalClose()}
              role="button"
              tabIndex="0"
              onKeyDown={() => {}}
            />
          </div>
          <StepsLine totalSteps={steps.length} currentStep={wizardCSV.step} titleSteps={steps} />
          <CSVWizard
            rows={wizardCSV.headerRows}
            fields={defaultFields}
            currentStep={wizardCSV.step}
            positions={positions}
            processFields={this.processCSVFields}
            endWizard={this.endCSVWizard}
            prospectModal
          />
        </div>
      );
    }

    return (
      <div id="investor-edit-modal">
        <ModelsHeader
          id="model-csv"
          modelId={model.id}
          title={`${model.id ? 'Edit' : 'Create'} Account`}
          onCSVInputChange={onCSVInputChange}
          copyButtonProps={copyButtonProps}
          modelProvider={accountProvider}
          prospectModal
        />

        <form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
          {/* Form section with `name` and `type` fields: */}
          <NameModelForm
            name={{ ...fields.name, refCb: this.saveNameInputRef }}
            value={account ? account.name : ''}
          />
          {/* Form section with `positions` subform: */}
          <PositionsFormset
            marketProvider={marketProvider}
            marketSecurities={marketSecurities}
            positions={fields.positions}
            totalError={errors._total}
            isComplianceOrAbove={isComplianceOrAbove}
            absoluteValues
            activeExclude
          />

          {error && <p className="text-danger">{error}</p>}

          <div className="text-sm-center">
            {/* don't use "submit" button type to prevent unintentional submitting by enter */}
            {model.id ? (
              <SubmitButton text="Save Account &amp; Run PRISM" disabled={submitting} />
            ) : (
              <SubmitButton text="Create Account &amp; Run PRISM" disabled={submitting} />
            )}
            <button type="button" className="btn btn-link" data-dismiss="modal" aria-label="Close">
              Back to {investor.is_prospect ? 'Prospects' : 'Clients'}
            </button>
          </div>
        </form>
        <div className="model-notification">
          We currently support: <b>Stocks, ETFs, Mutual Funds and Alternatives.</b> Our team is
          working on adding support for other security types. For specific questions, please contact{' '}
          <CustomerSupportEmailLink /> or call <CustomerSupportPhoneLink />
        </div>
      </div>
    );
  }
}

ModelEditInvestors.contextTypes = {
  user: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  ...ModelEditInvestors.contextTypes
};

export default reduxForm({
  form: 'editModel',
  fields: [
    'id', // hidden
    'name',
    'held_away',
    'type',
    '_total', // hidden, just to save wrong total value error
    'positions[].id', // hidden
    'positions[].security',
    'positions[].ticker_name',
    'positions[].type',
    'positions[].subtype',
    'positions[].sector',
    'positions[].ticker',
    'positions[].value',
    'positions[].prism_score_summary__overall',
    'positions[].deleted', // ie. "percentage value"
    'positions[].excluded'
  ],
  validate
})(BackendValidation(ModelEditInvestors));
