export const paragraph = str =>
  str
    .replace(/\n/g, ' ')
    .split(' ')
    .filter(part => part)
    .join(' ');

export const modelTitle = x => x.replace(/\(#\d+\)$/, '(Draft)');

export const isDraftTitle = x => !!x.match(/\(#\d{13}\)/g);
