import cn from 'classnames';
import Select from 'components/select';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ALL_ADVISORS_DEFAULT_VALUE } from './constants';
import './styles.scss';

const AdvisorFilter = ({
  isDisabled,
  advisors,
  className,
  allowAll,
  filter,
  onChange,
  onSearch
}) => {
  const { advisorByManager, authProvider, advisorProvider, user } = useContext(AdvisorContext);

  const [selectedAdvisorId, setSelectedAdvisorId] = useState(advisorByManager ?? user.advisor.id);

  useEffect(() => {
    // load the advisors list (only once)
    advisorProvider.es.list();
  }, []);

  useEffect(() => {
    // if the advisorByManager changes programmatically and not using the Select below,
    // update the selectedAdvisorId to make the Select reflect the changes.
    if (!_.isNil(advisorByManager) && advisorByManager !== selectedAdvisorId)
      setSelectedAdvisorId(advisorByManager);
  }, [advisorByManager]);

  if (!user || !advisors) return null;

  const advisorOptions = _.orderBy(
    advisors
      .filter(advisor => advisor.id !== user.advisor.id)
      .filter(advisor => {
        if (advisorByManager === advisor.id) return true;
        if (filter.withAccounts) return advisor.total_accounts > 0;
        if (filter.withClients) return advisor.total_clients > 0;
        if (filter.withProspects) return advisor.total_prospects > 0;
        if (filter.withHouseholds) return advisor.total_households > 0;
        if (filter.withModelPortfolios) return advisor.total_model_portfolios > 0;
        return true;
      })
      .map(advisor => ({ label: advisor.name, value: advisor.id })),
    ['label', 'value'],
    ['desc', 'asc', 'asc']
  );
  const hasAdvisorOptions = !_.isEmpty(advisorOptions);

  // add [Me] at the beginning of the list with the value `user.advisor.id` if there are
  // different options to show, or with `ALL_ADVISORS_DEFAULT_VALUE` to simulate the
  // 'All Advisors' option in case there is only one option to show. This will prevent
  // the list from loading twice when there is only one option.
  const meOption = {
    label: `[Me] ${user.first_name} ${user.last_name}`,
    value: hasAdvisorOptions ? user.advisor.id : ALL_ADVISORS_DEFAULT_VALUE
  };
  advisorOptions.unshift(meOption);

  // add "All Advisors" (if allowed) and `hasAdvisorOptions`
  if (allowAll && hasAdvisorOptions)
    advisorOptions.unshift({ label: 'All Advisors', value: ALL_ADVISORS_DEFAULT_VALUE });

  const handleChange = selected => {
    const { value } = selected;
    // verify that the selected value is a valid advisorOptions
    if (!advisorOptions.some(a => a.value === value)) return;

    // set the selected value in the internal state
    setSelectedAdvisorId(value);

    // setting the `advisorByManager` preserves the selection across different pages
    if (advisorByManager !== value) authProvider.setAdvisorByManager(value);

    // call onChange or onSearch callbacks
    if (onChange) onChange(value);
    else if (onSearch) {
      const params = { page: 1 };
      if (value !== 0) params.by_advisor = value;
      onSearch(params);
    }
  };

  // if the selected advisor is not available anymore, just pick `meOption`
  const selectedAdvisor = advisorOptions.find(a => a.value === selectedAdvisorId);
  if (!selectedAdvisor) handleChange(meOption);

  return (
    <div className={cn({ 'advisor-filter-disabled': isDisabled })}>
      <Select
        className={className}
        onChange={handleChange}
        options={advisorOptions}
        value={selectedAdvisor}
      />
    </div>
  );
};

AdvisorFilter.propTypes = {
  advisors: PropTypes.arrayOf(PropTypes.object),
  allowAll: PropTypes.bool,
  className: PropTypes.string,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  isDisabled: PropTypes.bool
};

AdvisorFilter.defaultProps = {
  advisors: null,
  allowAll: true,
  className: '',
  filter: {},
  onChange: null,
  onSearch: null,
  isDisabled: false
};

export default connect(state => ({
  advisors: state.advisors.esList
}))(AdvisorFilter);
