import { sortProviders } from 'app/containers/integrations-dashboard/utils';
import AvailableProvider from 'containers/integrations-dashboard/available-provider';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { availableIntegrationsProvidersSelector } from 'selectors/integrations';
import './styles.scss';

const AvailableIntegrations = ({ providers }) => (
  <div id="available-integrations">
    {providers.map(provider => (
      <AvailableProvider key={provider.id} provider={provider} />
    ))}
  </div>
);

AvailableIntegrations.propTypes = {
  integrations: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired
};

export default connect(state => ({
  providers: sortProviders(availableIntegrationsProvidersSelector(state))
}))(AvailableIntegrations);
