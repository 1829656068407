import SpinnerLoader from 'components/performance-spinner';
import React from 'react';
import { toast } from 'react-toastify';
import {
  MODEL_PORTFOLIO_CALCULATING_RISK_MESAGGE,
  MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE,
  MODEL_PORTFOLIO_CREATION_MESAGGE
} from './constants';

export const modelPortfolioCalculatingRiskToast = (
  message = MODEL_PORTFOLIO_CALCULATING_RISK_MESAGGE
) =>
  toast.info(
    () => (
      <div className="proposal-toast generating-div">
        <SpinnerLoader spinnerLoading />
        <div className="text-div">{message}</div>
      </div>
    ),
    { closeButton: true, autoClose: false, closeOnClick: false, timeout: 800 }
  );

export const createModelPortfolioCopy = async (portfolio, modelProvider, routerActions) =>
  modelProvider
    .create(portfolio)
    .then(async ({ data: model }) => {
      toast.info(MODEL_PORTFOLIO_CREATION_MESAGGE(model.name), { autoClose: 2000 });
      const riskNotification = modelPortfolioCalculatingRiskToast();
      routerActions.push(`/advisor/models/${model.id}/overview?copy=true`);
      await modelProvider.updatePrism(model.id).then(() => modelProvider.get(model.id));
      toast.update(riskNotification, { autoClose: 100 });
    })
    .catch(() => {
      toast.info(MODEL_PORTFOLIO_CREATION_ERROR_MESAGGE, { autoClose: 4000 });
    });
